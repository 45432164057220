import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { PortableText } from '@portabletext/react'
import Seo from '../components/Seo'
import SubLayout from '../components/layouts/SubLayout'

const OurTeamPage = ({ data }) => {
  const staffGroups = data.staffGroups.nodes.map(group => ({
    ...group,
    members: data.allSanityStaffMember.nodes.filter(
      member => member.staffGroup?._id === group._id
    )
  }))

  return (
    <SubLayout>
      <Seo title='Our Team' pathname='/our-team' />
      <StyledOurTeamPage>
        <h1>Our Team</h1>
        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          Our Team
        </p>

        {/* Staff Groups Section */}
        <section id="staff-groups">
          {staffGroups.map(group => (
            <article 
              key={group.slug.current} 
              className={`staff-group ${group.members?.length > 0 ? 'has-members' : ''}`}
            >
              <div className="group-header">
                {group.groupPhoto?.asset?.url && (
                  <img 
                    src={group.groupPhoto.asset.url} 
                    alt={group.name} 
                    className="group-photo"
                  />
                )}
                <div className="content">
                  {group.displayTitle && <h2>{group.name}</h2>}
                  {group.caption && (
                    <div className="caption">
                      <PortableText value={group.caption} />
                    </div>
                  )}
                </div>
              </div>

              {group.displayMembers && group.members && group.members.length > 0 && (
                <div className="group-members">
                  {group.members.map(({ firstName, lastName, role, bio, headshot }) => (
                    <article key={`${firstName}-${lastName}`} className='team-member'>
                      <img src={headshot.asset.url} alt={`${firstName} ${lastName}`} />
                      <div className="content">
                        <h2>{`${firstName} ${lastName}`}</h2>
                        <h3>{role}</h3>
                        <div className="bio">
                          <PortableText value={bio} />
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              )}
            </article>
          ))}
        </section>
      </StyledOurTeamPage>
    </SubLayout>
  )
}

export const query = graphql`
  query TeamPageQuery {
    staffGroups: allSanityStaffGroup(sort: {fields: order}) {
      nodes {
        _id
        name
        displayTitle
        caption: _rawCaption
        slug {
          current
        }
        groupPhoto {
          asset {
            url
          }
        }
        displayMembers
      }
    }
    allSanityStaffMember(sort: {fields: rank}) {
      nodes {
        firstName
        lastName
        role
        bio: _rawBio
        staffGroup {
          _id
        }
        headshot {
          asset {
            url
          }
        }
      }
    }
  }
`

const StyledOurTeamPage = styled.div`
  h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  #staff-groups {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 4rem;
  }

  .staff-group {
    text-align: center;
    margin-bottom: 4rem;
    
    .group-photo {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: #666;
    }

    .caption {
      p {
        text-align: justify;
        margin-bottom: 1rem;
      }
      
      strong {
        font-weight: 600;
      }
      
      em {
        font-style: italic;
      }
    }

    .group-members {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
      margin-top: 2rem;
    }

    &.has-members {
      grid-column: 1 / -1;
    }
  }

  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      margin-bottom: 1rem;
    }

    .content {
      text-align: center;
      width: 100%;

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.25rem;
      }

      h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #666;
      }

      .bio {
        p {
          text-align: justify;
          line-height: 1.6;
          margin-bottom: 1rem;
        }
        
        strong {
          font-weight: 600;
        }
        
        em {
          font-style: italic;
        }

        a {
          color: #0066cc;
          text-decoration: underline;
          
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    #staff-groups {
      grid-template-columns: repeat(2, 1fr);
    }

    .staff-group .group-members {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    #staff-groups {
      grid-template-columns: 1fr;
    }

    .staff-group {
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {    
    .team-member {
      text-align: center;
      
      img {
        margin: auto;
      }

      .bio p {
        text-align: justify;
      }
    }
  }
`

export default OurTeamPage